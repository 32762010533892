import React from "react";
import { useTranslation } from "react-i18next";

function Dashboard() {
  const { i18n } = useTranslation();
  const leport_link =
    i18n.language === "ru"
      ? "https://redash.codeforensics.ru/public/dashboards/Ub7gBWV1BoGRvpO3Qql2DiykwPzrvble9ToAbUvH?org_slug=default"
      : "https://redash.codeforensics.ru/public/dashboards/Lsd5MWni8vb3RdUzIYdtpeZH3Erg79wTAiUMuJ7g";

  return (
    <div>
      <iframe
        width={"100%"}
        height={window.innerHeight - 120}
        title="Dashboard"
        src={leport_link}
      />
    </div>
  );
}

export default Dashboard;
